import React, { useEffect, useState, useRef } from 'react';
import getApiBaseUrl from '../config'; // Ensure this is correctly set up

const Emulator486 = ({ username }) => {
  const [bootMessages, setBootMessages] = useState([]);
  const [currentMemoryLine, setCurrentMemoryLine] = useState(''); // To store the current memory line
  const [backendResponse, setBackendResponse] = useState([]); // For storing BBS backend response
  const [isLoading, setIsLoading] = useState(false); // To handle loading state
  const [hasBootSequenceStarted, setHasBootSequenceStarted] = useState(false); // To ensure boot sequence is only triggered once
  const [bbsLoginTriggered, setBbsLoginTriggered] = useState(false); // To control BBS login fetch

  // Refs to track the sound and if it has been played
  const hasPlayedSound = useRef(false);
  const bootSoundRef = useRef(null);

  useEffect(() => {
    // Initialize the boot sound only once
    if (!bootSoundRef.current) {
      bootSoundRef.current = new Audio('/286-startup.wav'); // Load the audio file
    }

    const playBootSound = () => {
      if (!hasPlayedSound.current) {
        const bootSound = bootSoundRef.current;
        bootSound.volume = 0; // Start at volume 0 for fade-in

        setTimeout(() => {
          bootSound.play().catch((error) => {
            console.error('Error playing boot sound:', error);
          });
          hasPlayedSound.current = true;

          // Implement fade-in to volume 0.3
          let volume = 0.0;
          const fadeInInterval = setInterval(() => {
            volume += 0.01; // Adjust increment for fade speed
            if (volume >= 0.3) {
              bootSound.volume = 0.3; // Set to target volume
              clearInterval(fadeInInterval);
            } else {
              bootSound.volume = volume;
            }
          }, 100); // Adjust interval for smoothness
        }, 7000); // Wait for 7 seconds before playing
      }
    };

    const fetchBootSequence = async () => {
      if (hasBootSequenceStarted) return; // Ensure boot sequence only triggers once

      setHasBootSequenceStarted(true); // Mark the boot sequence as started

      try {
        setIsLoading(true);

        // Play the boot sound before fetching the boot sequence
        playBootSound();

        const baseUrl = getApiBaseUrl(); // Get API base URL
        const response = await fetch(`${baseUrl}/start_boot_sequence`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username }),
        });
        const data = await response.json();

        if (data.bootMessages) {
          displayBootMessages(data.bootMessages);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching boot sequence:', error);
        setIsLoading(false);
      }
    };

    const fetchBBSLoginResponse = async () => {
      if (bbsLoginTriggered) return; // Prevent multiple BBS login fetch calls

      try {
        setBbsLoginTriggered(true); // Mark BBS login as triggered
        setIsLoading(true);
        const baseUrl = getApiBaseUrl();
        const response = await fetch(`${baseUrl}/486_execute`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            command: 'BBS login',
            username: username,
          }),
        });

        const result = await response.json();
        setBackendResponse(result.output); // Store the BBS login response from the backend
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching BBS login response:', error);
        setIsLoading(false);
      }
    };

    const displayBootMessages = (messages) => {
      messages.forEach((message, index) => {
        setTimeout(() => {
          if (message.includes('KB OK')) {
            // If it's a memory count line, update the current memory line without appending
            setCurrentMemoryLine(message);
          } else {
            // Otherwise, append to the boot messages
            setBootMessages((prev) => [...prev, message]);

            // Clear the current memory line after memory count is done
            if (message.includes('Checking extended memory') || message.includes('POST complete')) {
              setCurrentMemoryLine('');
            }

            if (message.includes('Password:') && !bbsLoginTriggered) {
              // Once the boot sequence reaches the password prompt, trigger BBS login fetch
              fetchBBSLoginResponse();
            }
          }
        }, index * 1000); // Adjust the delay between messages as needed
      });
    };

    if (username && !hasBootSequenceStarted) {
      fetchBootSequence(); // Trigger boot sequence if username is provided and not started yet
    }
  }, [username, hasBootSequenceStarted, bbsLoginTriggered]);

  const formatAsciiOutput = (asciiLines) => {
    return asciiLines.map((line, index) => (
      <pre key={index} className="whitespace-pre-wrap break-words">
        {line}
      </pre>
    ));
  };

  return (
    <div className="bg-black text-yellow-400 font-mono p-6 h-full w-full overflow-y-auto rounded-md">
      {isLoading && (
        <div className="text-center my-4">
          <div className="animate-pulse" style={{ animationDuration: '5s' }}>Loading...</div>
        </div>
      )}

      {bootMessages.map((message, index) => (
        <div key={index}>{message}</div>
      ))}

      {/* Display the current memory line separately */}
      {currentMemoryLine && <div>{currentMemoryLine}</div>}

      {/* Display the BBS backend response after password prompt */}
      {backendResponse.length > 0 && (
        <div className="mt-4">{formatAsciiOutput(backendResponse)}</div>
      )}
    </div>
  );
};

export default Emulator486;
