// src/Puzzle/Terminal.js

import React from 'react';
import TerminalLogic from '../desktopUI/TerminalLogic'; // Import TerminalLogic

const Terminal = ({ username, onSwitchTo486 }) => {  // Accept username as a prop
  return (
    <div className="relative bg-gray-800 p-4 h-[850px] w-[1250px] rounded-lg border-20 border-gray-700">
      {/* Terminal Logic */}
      <TerminalLogic username={username} onSwitchTo486={onSwitchTo486} />  {/* Pass down username */}
    </div>
  );
};

export default Terminal;
