import React, { useEffect } from 'react';

const LoginStatic = ({ onFinish }) => {
  useEffect(() => {
    // Set a timeout to show the static effect for 3 seconds
    const timer = setTimeout(() => {
      onFinish();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onFinish]);

  return (
    <div className="crt-static h-screen w-screen flex items-center justify-center">
      <div className="text-white text-4xl">Loading...</div>
    </div>
  );
};

export default LoginStatic;
