// src/desktopUI/TerminalLogic.js

import React, { useState, useEffect, useRef } from 'react';
import getApiBaseUrl from '../config'; // Adjust the import path as needed
import PropTypes from 'prop-types';

const TerminalLogic = ({ onSwitchTo486, username }) => {
  const [input, setInput] = useState('');
  const [history, setHistory] = useState([
    '######################################',
    '# Welcome to IBW Corporate Terminal #',
    '######################################',
    'Initializing system...',
    'Loading corporate resources...',
  ]);
  const [currentDir, setCurrentDir] = useState('/home');
  const [historyIndex, setHistoryIndex] = useState(null);
  const [commandHistory, setCommandHistory] = useState([]);
  const terminalRef = useRef(null);
  const inputRef = useRef(null);

  // Scroll to the bottom of the terminal whenever the history changes
  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [history]);

  // Add debugging for when the username is received
  useEffect(() => {
    console.log('Debug: Username received in TerminalLogic:', username);  // Debugging username value
    if (username) {
      console.log('Debug: Updating history with username:', username);  // Confirm updating the history with the username
      setTimeout(() => {
        setHistory((prevHistory) => [
          ...prevHistory,
          `login: ${username}`,
          `Password: ********`,
          'Last login: Mon Sep 25 14:32:09 on tty1',
          `Welcome to IBW, ${username}`,
        ]);
      }, 1000);  // Simulating a delay for realism
    } else {
      console.log('Debug: Username is undefined in TerminalLogic');  // If username is not passed, this will log
    }
  }, [username, currentDir]);

  const handleInput = async (e) => {
    if (e.key === 'Enter') {
      if (input.trim() === '') {
        setHistory((prev) => [...prev, 'Please enter a command.']);
        setInput('');
        return;
      }
      await processCommand(input);
      setCommandHistory([...commandHistory, input]);
      setHistoryIndex(null);
      setInput('');
    } else if (e.key === 'Tab') {
      e.preventDefault();
      await handleAutocomplete();
    } else if (e.key === 'ArrowUp') {
      navigateHistory(-1);
    } else if (e.key === 'ArrowDown') {
      navigateHistory(1);
    }
  };

  const navigateHistory = (direction) => {
    if (commandHistory.length === 0) return;

    let newIndex = historyIndex;

    if (newIndex === null) {
      newIndex = commandHistory.length - 1;
    } else {
      newIndex += direction;
      if (newIndex < 0) newIndex = 0;
      if (newIndex >= commandHistory.length) newIndex = commandHistory.length - 1;
    }

    setHistoryIndex(newIndex);
    setInput(commandHistory[newIndex]);
  };

  const handleAutocomplete = async () => {
    const baseUrl = getApiBaseUrl();
    try {
      const response = await fetch(`${baseUrl}/autocomplete`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ command: input, current_dir: currentDir }),
      });

      const result = await response.json();
      const matches = result.matches || [];

      if (matches.length > 0) {
        const cmdParts = input.trim().split(' ');
        cmdParts[cmdParts.length - 1] = matches[0];
        setInput(cmdParts.join(' ') + ' ');
      }
    } catch (error) {
      console.error('Autocomplete error:', error);
      setHistory((prev) => [...prev, 'Autocomplete error: ' + error.message]);
    }
  };

  const processCommand = async (command) => {
    const baseUrl = getApiBaseUrl();
    let newHistory = [...history];
    newHistory.push(`${username}@IBW-Terminal:${currentDir}$ ${command}`);  // Use the username

    try {
      const response = await fetch(`${baseUrl}/execute`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ command: command, current_dir: currentDir }),
      });

      if (!response.ok) {
        throw new Error(`Failed to execute command. Status: ${response.status}`);
      }

      const result = await response.json();
      if (result.output) {
        newHistory = newHistory.concat(result.output);
      } else {
        newHistory.push('Command executed with no output.');
      }

      setHistory(newHistory);
      setCurrentDir(result.current_dir || currentDir);

      if (command === 'cat man doo') {
        onSwitchTo486();  // Trigger the switch to the 486 emulator window
      }

    } catch (error) {
      console.error('Command execution error:', error);
      newHistory.push('Error executing command: ' + error.message);
      setHistory(newHistory);
    }
  };

  return (
    <div
      ref={terminalRef}
      className="bg-black text-green-400 font-mono p-6 h-full w-full overflow-y-auto rounded-md"
      onClick={() => inputRef.current.focus()}
    >
      {history.map((line, index) => (
        <div key={index}>{line}</div>
      ))}
      <div className="flex">
        <span>{username ? `${username}@IBW-Terminal:${currentDir}$` : 'login: ...'} </span>  {/* Show the prompt */}
        <input
          ref={inputRef}
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleInput}
          className="bg-transparent border-none outline-none text-green-400 flex-1"
          autoFocus
        />
      </div>
    </div>
  );
};

TerminalLogic.propTypes = {
  username: PropTypes.string.isRequired,
  onSwitchTo486: PropTypes.func.isRequired,
};

export default TerminalLogic;
