const getApiBaseUrl = () => {
  // Use the environment variable directly to set the API base URL
  const apiBaseUrl = process.env.REACT_APP_BACKEND_URL;

  if (!apiBaseUrl) {
    throw new Error("REACT_APP_BACKEND_URL is not set");
  }

  console.log("Using API Base URL:", apiBaseUrl);  // Log the API base URL for debugging
  return apiBaseUrl;
};

export default getApiBaseUrl;
