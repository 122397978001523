import React, { useState } from 'react';
import getApiBaseUrl from '../config';  // Adjust the path if necessary
import PropTypes from 'prop-types'; // For prop type checking

const LoginForm = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleLogin = async () => {
    try {
      const baseUrl = getApiBaseUrl();
      console.log(`Sending login request to: ${baseUrl}/login`);

      const response = await fetch(`${baseUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });

      const result = await response.json();
      console.log('Login response:', result);

      if (response.ok && result.success) {
        console.log('Passing username to onLogin:', username); // Add this log
        onLogin(username);  // Pass username to onLogin function
      } else {
        setError(result.error || 'Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('An error occurred during login. Please try again.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <form onSubmit={handleSubmit} className="relative bg-gray-200 border-4 border-gray-400 rounded-lg shadow-lg max-w-sm w-full p-4" style={{ width: '320px' }}>
      <div className="bg-blue-600 text-white py-1 px-2 mb-4 rounded-t-lg">
        <h1 className="font-bold text-lg">Login</h1>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2 text-gray-800">Username:</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="w-full p-2 border border-gray-400 rounded"
          placeholder="Enter username"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2 text-gray-800">Password:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full p-2 border border-gray-400 rounded"
          placeholder="Enter password"
        />
      </div>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="flex justify-between">
        <button
          type="submit"
          className="bg-gray-300 border border-gray-600 hover:bg-gray-400 text-black font-semibold py-1 px-4 rounded"
        >
          OK
        </button>
        <button
          type="button"
          onClick={() => { /* Implement cancel functionality if needed */ }}
          className="bg-gray-300 border border-gray-600 hover:bg-gray-400 text-black font-semibold py-1 px-4 rounded"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default LoginForm;
