import React, { useState, useEffect } from 'react';
import Terminal from './Puzzle/Terminal';
import LoginStatic from './components/LoginStatic';
import Emulator486 from './components/Emulator486';
import DraggableWindow from './desktopUI/DraggableWindow';
import LoginForm from './components/LoginForm';
import getApiBaseUrl from './config.js';  // Get the API base URL

const apiBaseUrl = getApiBaseUrl();  // Get the base URL from config
const nodeEnv = process.env.NODE_ENV || 'Not Set';
const reactAppEnvironment = process.env.REACT_APP_ENVIRONMENT || 'Not Set';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [showStatic, setShowStatic] = useState(false);
  const [showEmulator, setShowEmulator] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#008080');
  const [username, setUsername] = useState('');
  const [apiConnectionStatus, setApiConnectionStatus] = useState('Checking...');

  const handleLogin = (usernameInput) => {
    console.log('Received username in handleLogin:', usernameInput);
    setUsername(usernameInput);
    setShowStatic(true);  // Trigger static effect after login
  };

  const handleStaticFinish = () => {
    console.log('Static effect finished, proceeding to terminal');
    setLoggedIn(true);  // Show terminal after static effect
  };

  const handleSwitchTo486Emulator = () => {
    console.log('Switching to 486 emulator');
    setShowEmulator(true);
    setBackgroundColor('#000000');  // Change background to black for emulator
  };

  const handle486Boot = async () => {
    console.log('Starting 486 boot sequence');
    setShowEmulator(true);
    setBackgroundColor('#000000');

    try {
      const response = await fetch(`${apiBaseUrl}/start_boot_sequence`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username }),
      });
      
      const data = await response.json();
      if (data.bootMessages) {
        console.log('Boot sequence messages received:', data.bootMessages);
        // You can pass bootMessages to the Emulator486 if needed
      }
    } catch (error) {
      console.error('Error starting 486 boot sequence:', error);
    }
  };

  useEffect(() => {
    const checkApiConnection = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/health`, { method: 'GET' });
        if (response.ok) {
          setApiConnectionStatus('Connected');
        } else {
          setApiConnectionStatus(`Disconnected (Status: ${response.status})`);
        }
      } catch (error) {
        setApiConnectionStatus(`Disconnected (${error.message})`);
      }
    };

    checkApiConnection();
  }, [apiBaseUrl]);

  return (
    <div className="relative h-screen" style={{ backgroundColor }}>
      {/* Top Bar */}
      {loggedIn && (
        <div className="fixed top-0 left-0 w-full bg-gray-900 text-white py-2 px-4 flex items-center justify-between z-50">
          <div className="flex space-x-4">
            <span className="hover:bg-gray-700 p-2 rounded cursor-pointer">File</span>
            <span className="hover:bg-gray-700 p-2 rounded cursor-pointer">Edit</span>
            <span className="hover:bg-gray-700 p-2 rounded cursor-pointer">View</span>
          </div>
        </div>
      )}

      {/* Emulator Screen */}
      {showEmulator && (
        <div className="absolute inset-x-0 top-[50px] bg-black z-40 flex items-start justify-center">
          <Emulator486 username={username} />
        </div>
      )}

      {/* Main Content */}
      <div className={`flex items-center justify-center ${loggedIn ? 'pt-16' : 'h-screen'}`}>
        {/* Yi3ld Text */}
        <div
          className={`absolute transition-all duration-1000 ease-in-out ${
            loggedIn
              ? 'top-0 right-0 transform scale-50 translate-x-4 -translate-y-6 max-h-[calc(100%-40px)]'
              : 'top-1/4 inset-x-0'
          } flex justify-center`}
          style={{ zIndex: loggedIn ? 100 : 50 }}
        >
          <div className="text-8xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-red-500 via-yellow-500 via-green-500 via-blue-500 to-purple-500 transition-transform duration-1000 ease-in-out">
            Yi3ld.
          </div>
        </div>

        {/* Debugging Info */}
        <div className="absolute bottom-5 left-5 bg-gray-700 text-white p-2 rounded-md">
          <p><strong>API Base URL:</strong> {apiBaseUrl}</p>
          <p><strong>Environment:</strong> {reactAppEnvironment}</p>
          <p><strong>API Connection Status:</strong> {apiConnectionStatus}</p>
          <p><strong>Username state:</strong> {username || 'Not Logged In'}</p>
        </div>

        {/* Login and Terminal Logic */}
        {!loggedIn ? (
          showStatic ? (
            <LoginStatic onFinish={handleStaticFinish} />
          ) : (
            <LoginForm onLogin={handleLogin} />
          )
        ) : showEmulator ? null : (
          <DraggableWindow>
            {username ? (
              <>
                {console.log('Rendering Terminal with username:', username)}
                <Terminal username={username} onSwitchTo486={handleSwitchTo486Emulator} />
              </>
            ) : (
              <p>Loading user...</p>
            )}
          </DraggableWindow>
        )}
      </div>
    </div>
  );
}

export default App;
